const stepcheck = {
  dataRef:{
    // pressure_qff_mb_derived: null,
    // pressure_qfe_mb_derived: null,
    // temp_drybulb_c_tttttt: null,
    // temp_wetbulb_c: null,
    // relative_humidity_pc: null
  },

  data: {
    pressure_qff_mb_derived: null,
    pressure_qfe_mb_derived: null,
    temp_drybulb_c_tttttt: null,
    temp_wetbulb_c: null,
    relative_humidity_pc: null
  },

  param: {
    pressure_qff_mb_derived: ["mp_qff_before", "mp_qff_after"],
    pressure_qfe_mb_derived: ["mp_qfe_before", "mp_qfe_after"],
    temp_drybulb_c_tttttt: ["td_before", "td_after"],
    temp_wetbulb_c: ["twc_before", "twc_after"],
    relative_humidity_pc: ["mh_before", "mh_after"]
  },

  check(value, key) {
    console.log('SC check', value, key, this.data[key])
    let status = true;
    let message = "";

    let min = this.data[key]?.before;
    let max = this.data[key]?.after;

    if (min === undefined || max === undefined ) {
      return { status: status, message: message };
    }

    let min_pass = false;
    if (min === null) {
      min_pass = true;
    } else {
      min_pass = value >= min;
    }

    let max_pass = false;
    if (max === null) {
      max_pass = true;
    } else {
      max_pass = value <= max;
    }

    if (value === "" || value === null  || (min_pass && max_pass) || value == 9999) {
      status = true;
      message = "";
    } else {
      status = false;
      message = "Step Check - Selisih Batas Bawah " + min + ", Selisih Batas Atas " + max;
    }
    // console.log('return', status, message)
    return { status: status, message: message };
  },

  padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  },

  setData(last_value, qc_params, qc_jam, qc_key) {
    
    let qc_data = this.dataRef; //[qc_params]
    // console.log('SC_helper - setData : ', last_value, qc_data, qc_params, qc_jam)
    try {
      const before = Math.abs(qc_data[qc_key+qc_params+"_stepcheck_"+this.padTo2Digits(qc_jam)+"_before"]);
      const after = Math.abs(qc_data[qc_key+qc_params+"_stepcheck_"+this.padTo2Digits(qc_jam)+"_after"]);
      console.log('qc_jam', qc_jam, last_value, before, after, {"before": (+last_value - before), "after": (+last_value + after)})
      this.data[qc_params] = {"before": (+last_value - before), "after": (+last_value + after)}
    } catch (error) {
      console.log('error', error)
    }
    
  },

  // setData(last_value, qc_params, qc_jam) {
    
  //   let qc_data = this.dataRef[qc_params]
  //   console.log('SC_helper - setData : ', last_value, qc_data, qc_params, qc_jam)
  //   try {
  //     if (qc_data && Object.keys(qc_data).length > 0) {
  //       if (qc_data.hasOwnProperty(qc_jam)) {
  //         let before = Math.abs(qc_data[qc_jam][this.param[qc_params][0]]) 
  //         let after = Math.abs(qc_data[qc_jam][this.param[qc_params][1]]) 
  //         console.log('qc_jam', {"before": (+last_value - before), "after": (+last_value + after)})
  //         this.data[qc_params] = {"before": (+last_value - before), "after": (+last_value + after)}
  //       }
  //     }
  //   } catch (error) {
  //     console.log('error', error)
  //   }
  // },

  saveStepCheckReference(qc_data) {
    // console.log('saveStepCheckReference', qc_data, qc_params)
    // this.dataRef[qc_params] = qc_data
    this.dataRef = qc_data
  },

  getData() {
    return this.data
  },

  getDataRef() {
    return this.dataRef
  }

};

export default stepcheck;
