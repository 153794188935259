/**
 * This file contains stateless helper function for synop
 */

/**
 * Generic function
 */
export function is_3hourly_obs(jam_pengamatan) {
  return is_main_hour(jam_pengamatan) || is_intermediate_hour(jam_pengamatan);
}

export function is_main_hour(jam_pengamatan) {
  return (
    jam_pengamatan === "00:00" ||
    jam_pengamatan === "06:00" ||
    jam_pengamatan === "12:00" ||
    jam_pengamatan === "18:00"
  );
}

export function is_intermediate_hour(jam_pengamatan) {
  return (
    jam_pengamatan === "03:00" ||
    jam_pengamatan === "09:00" ||
    jam_pengamatan === "15:00" ||
    jam_pengamatan === "21:00"
  );
}

export function counter_clockwise_range(a, b) {
  // For example
  // a adalah start_hour, b adalah jam pengamatan
  // a = 21, b = 03, range 6 jam
  // a = 22, b = 00, range 2 jam
  const range = a - b;
  if (range < 0) {
    return range + 24;
  }
  return range;
}

/**
 * Form Me48
 */
export function get_combination_clouds(list_jenis, list_opsi) {
  // Get value and text sorted
  // contoh ["6", "9", "7", "/", null] to ["9", "7", "6", "/", null]
  let jenis = [];
  for (let index in list_jenis) {
    const current_jenis = list_jenis[index];
    jenis.push(list_opsi.filter((j) => j.value == current_jenis));
  }
  jenis = jenis.sort((a, b) => a[0]?.value - b[0]?.value);

  // Extract text
  let uniqueJenis = jenis.reduce((acc, curr) => {
    // ignore null arr len 0
    if (curr.length > 0) {
      let str = curr[0].text;
      // match (Sc/St/Cu/Cb) in jenis awan
      // otherwise / cloud not visible
      let matches = str.match(/\((.*?)\)/);
      let result = matches ? matches[1] : "/";
      if (!acc.includes(result)) acc.push(result);
    }
    return acc;
  }, []);

  if (uniqueJenis?.length === 1 && uniqueJenis[0] === "/") {
    return "-";
  } else {
    uniqueJenis?.reverse()
    return uniqueJenis?.filter((uj) => uj !== "/").join("") || 0;
  }
}

/**
 * Sandi
 */

/** for 8NsChshs */
export function get_available_cloud(awan_rendah, awan_menengah, awan_tinggi) {
  // Logic to filter awan yang sesuai
  // Due to weird data structure so weed to specify n
  // Awan Jumlah 9 dan / tidak diambil di lapisan, awan jumlah 9 hanya dibaca di cloud cover
  const filter_awan = (awan, n) => {
    const result = [];
    for (let i = 0; i < n; i++) {
      if (
        awan.jenis[i] != "" &&
        awan.jenis[i] != "/" &&
        awan.jenis[i] != null &&
        awan.jumlah[i] != "9" &&
        awan.jumlah[i] != "/" &&
        awan.jumlah[i] != "" &&
        awan.jumlah[i] != null
      )
        result.push({
          jenis: awan.jenis[i],
          jumlah: +awan.jumlah[i],
          tinggi_dasar: +awan.tinggi_dasar[i],
        });
    }
    return result;
  };

  // Combine all clouds
  const clouds = [
    ...filter_awan(awan_rendah, 3),
    ...filter_awan(awan_menengah, 2),
    ...filter_awan(awan_tinggi, 2),
  ];

  return clouds;
}

export function get_lowest_cloud(awan_rendah, awan_menengah, awan_tinggi) {
  const cloud_sorted = get_available_cloud(
    awan_rendah,
    awan_menengah,
    awan_tinggi
  ).sort((a, b) => a.tinggi_dasar - b.tinggi_dasar);
  console.log("cloud_sorted", cloud_sorted);
  if (cloud_sorted.length > 0) {
    return cloud_sorted[0].tinggi_dasar;
  } else {
    return null;
  }
}

export function get_source_nh(ncl, ncm) {
  let Nh = ncl;
  if (Nh == "" || Nh == "0" || Nh == "-" || Nh == "/") {
    Nh = ncm;
    if (Nh == "" || Nh == "0" || Nh == "-" || Nh == "/") {
      Nh = "0";
    }
  }
  return Nh;
}

/** for 6RRRtr seksi 1 */
export function get_RRRtr_section1(
  jam_pengamatan,
  rainfall_jumlah_24h,
  rainfall_jumlah_6h
) {
  let RRR = 0;
  let t = 0;
  if (jam_pengamatan === "00:00") {
    RRR = rainfall_jumlah_24h == 9999 ? 9999 : rainfall_jumlah_24h;
    t = rainfall_jumlah_24h == 9999 ? "/" : "4";
  } else if (
    jam_pengamatan === "06:00" ||
    jam_pengamatan === "12:00" ||
    jam_pengamatan === "18:00"
  ) {
    RRR = rainfall_jumlah_6h == 9999 ? 9999 : rainfall_jumlah_6h;
    t = rainfall_jumlah_6h == 9999 ? "/" : "1";
  }

  if (RRR == 9999) {
    return "6////";
  } else if (RRR == 8888) {
    RRR = "990";
    return "6" + RRR + t;
  } else if (RRR > 0) {
    if (RRR < 1) {
      RRR = `99${Math.round(RRR * 10)}`;
    } else if (RRR < 989) {
      RRR = padZero(Math.round(RRR), 3);
    } else {
      RRR = "989";
    }
    return "6" + RRR + t;
  }
  return null;
}

/** for 6RRRtr seksi 3 */
export function get_RRRtr_section3(
  jam_pengamatan,
  t_operating_hours,
  t_start_hour,
  t_end_hour,
  rainfall_last_mm
) {
  let RRR = rainfall_last_mm;
  let t = 7; // only for 24h
  let RRRtr = null;

  // Determine Tr for other than 24h
  if (t_operating_hours !== null && t_operating_hours < 24) {
    let jeda = 3;
    // bisa diganti dengan counter_clock_wise
    const current_obs_time =
      +jam_pengamatan.split(":")[0] == 0 ? 24 : +jam_pengamatan.split(":")[0];

    // Jam pengamatan saat mulai operating hour
    if (jam_pengamatan === `${padZero(t_operating_hours, 2)}:00`) {
      // Misal start 21, end 12, obstime 21
      // Misal start 00, end 15, obstime 00

      // Find interval
      const t_start_hour_norm = t_start_hour == 0 ? 24 : t_start_hour;
      jeda = t_start_hour_norm - t_end_hour;
    } else if (Math.abs(current_obs_time - t_start_hour) < 3) {
      // Misal start 22, obstime 00, end 12
      // maka 24 - 12 = 12 jam yll
      // Misal start 23, end 10
      // maka 24 - 9 = 15 jam yll (obsttime 00)
      // maka 03 - 00 = 3 jam yll (obsttime 03)

      jeda = current_obs_time - floorTo(t_end_hour, 3);
    }

    t =
      jeda == null
        ? "0"
        : jeda == 6
        ? "1"
        : jeda == 12
        ? "2"
        : jeda == 18
        ? "3"
        : jeda == 24
        ? "4"
        : jeda == 1
        ? "5"
        : jeda == 2
        ? "6"
        : jeda == 3
        ? "7"
        : jeda == 9
        ? "8"
        : jeda == 15
        ? "9"
        : "0";
  }

  let sandi_tr = null;
  if (t) {
    sandi_tr = t;
  }

  // Determine RRR
  if (RRR) {
    if (RRR > 0 && RRR != 9999 && RRR != 8888) {
      if (RRR < 1) {
        RRR = `99${Math.round(RRR * 10)}`;
      } else if (RRR < 989) {
        RRR = padZero(Math.round(RRR), 3);
      } else {
        RRR = "989";
      }
      // console.log('sesi 3 - 6RRRtR', RRR, t)
      RRRtr = "6" + RRR + t;
    } else {
      if (RRR == 8888) {
        RRR = "990";
      } else if (RRR == 9999) {
        RRR = "///";
        t = "/";
      }
      // console.log('sesi 3 - 6RRRtR - else', RRR, t)
      RRRtr = "6" + RRR + t;
    }
  }

  return [RRRtr, sandi_tr];
}

/** for 5appp */
export function get_pressure_difference_and_tendency(
  qff,
  pressure_tend_1h_mb_www,
  pressure_tend_2h_mb_www,
  pressure_tend_3h_mb_www,
  t_operating_hours,
  t_start_hour,
  jam_pengamatan
) {
  // const current_obs_time =
  //   +jam_pengamatan.split(":")[0] == 0 && t_start_hour != 0
  //     ? 24
  //     : +jam_pengamatan.split(":")[0];

  // Case
  // 21-12 maka obs 21 5////
  // 00-12 maka obs 00 5////
  // 00-12 maka obs 03 bukan 5////
  // seharusnya bisa dengan pressure_tend_3/2/1h_mb_www, namun ada bug dari BE
  // if (t_operating_hours < 24 && Math.abs(current_obs_time - t_start_hour) < 3)
  const range = counter_clockwise_range(
    +jam_pengamatan.split(":")[0],
    t_start_hour
  );
  if (t_operating_hours < 24 && range < 3) {
    return "5////";
  }

  let ppp = qff;
  if (
    ppp &&
    ppp != 9999 &&
    pressure_tend_3h_mb_www &&
    pressure_tend_3h_mb_www != 9999 &&
    pressure_tend_2h_mb_www &&
    pressure_tend_2h_mb_www != 9999 &&
    pressure_tend_1h_mb_www &&
    pressure_tend_1h_mb_www != 9999
  ) {
    let a = null;

    let ppps = [
      pressure_tend_3h_mb_www,
      pressure_tend_2h_mb_www,
      pressure_tend_1h_mb_www,
    ];

    // this algorithm base on Handbook for the Meteorological Observation KNMI September 2000
    const A = parseFloat((ppp - ppps[0]).toFixed(1));
    const B = parseFloat((ppp + ppps[0] - ppps[2] - ppps[1]).toFixed(1));
    const C = parseFloat((ppp - ppps[2]).toFixed(1));

    if ((A > 0.0 && B < 0.0 && C < 0.0) || (A == 0.0 && B < 0.0 && C != null)) {
      a = "0";
    } else if (A > 0.0 && B < 0.0 && C >= 0.0) {
      a = "1";
    } else if (A > 0.0 && B == 0.0 && C != null) {
      a = "2";
    } else if (A > 0.0 && B > 0.0 && C != 0) {
      a = "3";
    } else if (A == 0.0 && B == 0.0 && C != null) {
      a = "4";
    } else if (
      (A == 0.0 && B > 0.0 && C != null) ||
      (A < 0.0 && B > 0.0 && C > 0.0)
    ) {
      a = "5";
    } else if (A < 0.0 && B > 0.0 && C <= 0.0) {
      a = "6";
    } else if (A < 0.0 && B == 0.0 && C != null) {
      a = "7";
    } else if (A < 0.0 && B < 0.0 && C != null) {
      a = "8";
    }

    // ambil selisih tekanan sekarang - tekanan 3 jam yang lalu
    // console.log('5appp hasil ', ppp, ppps[0])
    ppp = ppp - ppps[0];
    // console.log('ppp', ppp, ppps[0])

    if (a == null) {
      if (ppp > 0) {
        a = "2";
      } else if (ppp < 0) {
        a = "7";
      } else {
        a = "4";
      }
    }

    ppp = parseFloat(ppp.toFixed(1));
    if (ppp < 0) ppp = -ppp;
    ppp = padZero(ppp * 10, 3);

    return "5" + a + ppp;
  } else {
    return "5////";
  }
}

/**
 * Locally use function
 */

function padZero(val, n) {
  return `${"0".repeat(n)}${val}`.substr(-n, n);
}

function floorTo(val, n) {
  return Math.floor(val / n) * n;
}

// RRR = this.input_data.rainfall_last_mm;
// t = 7;
// if (this.jam === "00:00" && this.t_operating_hours !== null && this.t_operating_hours < 24) {
//   const jeda = 24 - this.floorTo(this.t_operating_hours, 3);
//   t = jeda == null ? "0" : jeda == 6 ? "1" : jeda == 12 ? "2" : jeda == 18 ?
//     "3" : jeda == 24 ? "4" : jeda == 1 ? "5" : jeda == 2 ? "6" : jeda == 3 ?
//     "7" : jeda == 9 ? "8" : jeda == 15 ? "9" : "0";
// }
// if (t) {
//   this.sandi_tr = t;
// } else {
//   this.sandi_tr = null;
// }

// if (RRR) {
//   if (RRR > 0 && RRR != 9999 && RRR != 8888) {
//     if (RRR < 1) {
//       RRR = `99${Math.round(RRR * 10)}`;
//     } else if (RRR < 989) {
//       RRR = this.padZero(Math.round(RRR), 3);
//     } else {
//       RRR = "989";
//     }
//     // console.log('sesi 3 - 6RRRtR', RRR, t)
//     result.push("6" + RRR + t);
//   }
//   else {
//     if (RRR == 8888) {
//       RRR = "990"
//     }
//     else if (RRR == 9999) {
//       RRR = "///"
//       t = "/"
//     }
//     // console.log('sesi 3 - 6RRRtR - else', RRR, t)
//     result.push("6" + RRR + t);
//   }
// }
